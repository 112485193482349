import React from 'react';
import '../styles/intro.scss';
import '../styles/servicehomepage.scss';
import {
    AppLayout, Box,
    Button,
    Container, Grid,
    HelpPanel,
    SideNavigation,
    SpaceBetween,
    TextContent
} from "@amzn/awsui-components-react";
import NavigationPanel from "./NavigationPanel";

// This is not meant to be a template, rather it is the
// introduction page that you see upon loading the webserver.
export default function APPSIntro() {
    return (
        <AppLayout
            navigation={<NavigationPanel/>}
            content={<Content/>}
            tools={<Tools/>}
            disableContentPaddings={true}
        />
    );
}

const Content = () => (
    <div>
        <TextContent>
            <div>
                <Grid className="custom-home__header" disableGutters={true}>
                    <Box margin="xxl" padding={{ vertical: 'xxxs', horizontal: 'l' }}>
                        <div className="custom-home__header-title">
                            <Box fontSize="display-l" fontWeight="bold" color="inherit">
                                Android Players Perf Script
                            </Box>
                            <Box fontWeight="light">
                <span className="custom-home__header-sub-title">
                  Orchestrator service that generates stream metadata to analyze Android Player performance.
                </span>
                            </Box>
                        </div>
                    </Box>
                </Grid>
            </div>

            <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
                <Box padding={{ vertical: 'xxxs' }}>
                    <Grid
                        gridDefinition={[
                            { offset: { l: 10, xxs: 1 }, colspan: { l: 1.5, xxs: 10 } },
                            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
                            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
                        ]}
                    >
                        <Container>
                            <SpaceBetween size="xl">
                                <Box variant="h2" padding="n">
                                    Submit Perf Run
                                </Box>
                                <Button href="#/schedule-run" variant="primary">
                                    Create Submission
                                </Button>
                            </SpaceBetween>
                        </Container>
                    </Grid>
                </Box>
            </Grid>

            <Box margin="xxl" padding="l">
                <SpaceBetween size="l">
                    <div>
                        <h1>About</h1>
                        <Container>
                            <div>
                                One Stop solution for 'Scheduling / Running / Validating' player performance, Before releasing new
                                player to BETA or PROD customers.
                                <ol>
                                    <li> Allows to generate large amount of streams on 3p emulator On Demand.</li>
                                    <li> Also allows to schedule the runs daily to catch the regression earliest possible. </li>
                                    <li>
                                        Handles the orchestration of downstream systems to run multiple instances of emulators to generate
                                        the required stream count.
                                    </li>
                                    <li>
                                        Generates the custom link to the Sauron to view the generated stream metadata. Allowing us to easily
                                        deep dive between the player versions.
                                    </li>
                                </ol>
                            </div>
                        </Container>
                    </div>
                    <div>
                        <h1>Access Roles</h1>
                        <Container>
                            <div>
                                <ol>
                                    <li> Admin / Scheduler Access </li>
                                    <ul>
                                        <li>
                                            Will have access to Submit / Scheduler access, allowing user to submit / edit / modify / delete
                                            runs.
                                        </li>
                                    </ul>
                                    <li> Generic / Viewer Access </li>
                                    <ul>
                                        <li> Will have access to view the results of completed run/s. </li>
                                    </ul>
                                </ol>
                            </div>
                        </Container>
                    </div>
                    <div>
                        <h1>How it works</h1>
                        <Container>
                            <div>
                                <ol>
                                    <li>
                                        Open 'Submit Run' page to submit the jobs.
                                        <ul>
                                            <li>
                                                Users can select 'Run Now' option to create on demand run. This would start the run at earliest
                                                possible time based on availability of the resources.
                                            </li>
                                            <li>
                                                Users can select 'Run Later' option to create a schedule. Scheduler will trigger the run later
                                                in the day when resources are free and available.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Open 'Sucheduled Run/s' page to view / modify / delete scheduled runs.
                                        <ul>
                                            <li>
                                                Once user submits run with 'Run later' option, a new entry will be added to the DB with the
                                                requested info.
                                            </li>
                                            <li>
                                                User will be able to view these submissions and should be able to edit/delete these runs.
                                            </li>
                                            <li>
                                                Once the job is submitted to downstream systems to generate streams, the entry from the db will
                                                be removed and will not be visible on UI.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        Internally APPS will have the entries of the jobs submitted to down stream systems. APPS will be
                                        polling the downstream system to know the completion of jobs. As the jobs are completed the URL for
                                        results dashboard(Sauron) would be created and stored in db for 30 days.
                                    </li>
                                    <li>
                                        'Result/s' page is NOT restricted and will be accessible by everyone in PV. Users should be able to
                                        view all the test run results back dating to 30 days and will have the link to Sauron dashboard to
                                        deep dive further.
                                    </li>
                                </ol>
                            </div>
                        </Container>
                    </div>
                </SpaceBetween>
            </Box>
        </TextContent>
    </div>
);

const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
        <p>Help content goes here</p>
        <ul>
            <li> Team Wiki Home </li>
            <li> Learn More wiki links </li>
            <li> Report Issues </li>
            <li> Suggest Upgrades </li>
            <li> Contact Us </li>
        </ul>
    </HelpPanel>
);
