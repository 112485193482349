import React, {useState} from 'react';
import NavigationPanel from './NavigationPanel';
import {
    AppLayout,
    BreadcrumbGroup,
    Button,
    ColumnLayout,
    Form,
    ButtonDropdown,
    FormField,
    Input,
    RadioGroup,
    Header,
    SpaceBetween,
    Container,
    HelpPanel,
    Icon,
    Multiselect,
    DatePicker
} from "@amzn/awsui-components-react";

import '../styles/form.scss';

// Class ScheduleRun is a skeleton of a Single page create form using AWS-UI React components.
export default class ScheduleRun extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AppLayout
                navigation={<NavigationPanel/>} // Navigation panel content imported from './NavigationPanel.tsx'
                breadcrumbs={<Breadcrumbs />}
                contentHeader={<Header variant="h1">Submit / Schedule Android Perf Run</Header>}
                content={<Content />}
                tools={Tools}
                contentType="default"
            />
        );
    }
}

// The content in the main content area of the App layout
const Content = props => {
    return (
        <Form
            actions={
                // located at the bottom of the form
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link">Cancel</Button>
                    <Button href="#/table" variant="primary">
                        Submit Perf Run
                    </Button>
                </SpaceBetween>
            }
        >
            <SpaceBetween direction="vertical" size="l">
                <Container header={<Header variant="h2">Test Run Info</Header>}>
                    <SpaceBetween size="l">
                        <FormField label="Test Run Name" stretch={true}>
                            <Input type="text" value={props.value || ''}/>
                        </FormField>
                        <FormField stretch={true}>
                            <ColumnLayout columns={2}>
                                <FormField label="Release Branch Name">
                                    <ButtonDropdown
                                        items={[
                                            { text: 'dv_nightly', id: 'dv_nightly', disabled: false },
                                            { text: 'dv_r326_pikachu', id: 'dv_r326_pikachu', disabled: false },
                                            { text: 'dv_r327_quicksilver', id: 'dv_r327_quicksilver', disabled: false },
                                            { text: 'dv_r328_rambo', id: 'dv_r328_rambo', disabled: false }
                                        ]}
                                    >
                                        Kbits branch
                                    </ButtonDropdown>
                                </FormField>
                                <FormField label="Release APK version">
                                    <Input type="number" value="0" />
                                </FormField>
                                <FormField label="Current Branch Name">
                                    <ButtonDropdown
                                        items={[
                                            { text: 'dv_nightly', id: 'dv_nightly', disabled: false },
                                            { text: 'dv_r326_pikachu', id: 'dv_r326_pikachu', disabled: false },
                                            { text: 'dv_r327_quicksilver', id: 'dv_r327_quicksilver', disabled: false },
                                            { text: 'dv_r328_rambo', id: 'dv_r328_rambo', disabled: false }
                                        ]}
                                    >
                                        Kbits branch
                                    </ButtonDropdown>
                                </FormField>
                                <FormField label="Current APK version">
                                    <Input type="number" value="0" />
                                </FormField>
                            </ColumnLayout>
                        </FormField>
                        <FormField label="Stream Count" stretch={true}>
                            <Input type="number" value="1000" />
                        </FormField>
                        <FormField
                            label="Test Modes" stretch={true}
                            description= "Select Test/s to Execute." >
                            <Multiselect
                                deselectAriaLabel={e => `Remove ${e.label}`}
                                options={[
                                    {
                                        label: 'Performance Tests',
                                        value: '1'
                                    },
                                    {
                                        label: 'CPU/Memory Profiling',
                                        value: '2'
                                    }
                                ]}
                                placeholder="Select Test/s"
                                selectedAriaLabel="Selected"
                                selectedOptions={props.value}/>
                        </FormField>
                        <FormField
                            label="Execution method"
                            description="Choose 'Run Now' for on demand run OR 'Run Later' to schedule the run. "
                            stretch={true}
                        >
                            <RadioGroup items={[{ value: 'first', label: 'Run Now' }, { value: 'second', label: 'Run Later' }]}
                                        value={props.value}/>
                        </FormField>
                        <FormField stretch={true}>
                            <ColumnLayout columns={2}>
                                <FormField label="Schedule Start Date">
                                    <DatePicker
                                        openCalendarAriaLabel={selectedDate =>
                                            'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                                        }
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        value={props.value}/>
                                </FormField>
                                <FormField label="Schedule End Date">
                                    <DatePicker
                                        openCalendarAriaLabel={selectedDate =>
                                            'Choose Date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                                        }
                                        nextMonthAriaLabel="Next month"
                                        placeholder="YYYY/MM/DD"
                                        previousMonthAriaLabel="Previous month"
                                        todayAriaLabel="Today"
                                        value={props.value}/>
                                </FormField>
                            </ColumnLayout>
                        </FormField>
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Form>
    );
};

// Breadcrumb content
const Breadcrumbs = () => (
    <BreadcrumbGroup
        items={[
            {
                text: ' A P P S ',
                href: '#/'
            },
            {
                text: 'Schedule Run',
                href: '#/schedule-run'
            }
        ]}
    />
);

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
    <HelpPanel
        header={<h2>Submit / Schedule Run</h2>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                <ul>
                    <li> Team Wiki Home </li>
                    <li> Learn More wiki links </li>
                    <li> Report Issues </li>
                    <li> Suggest Upgrades </li>
                    <li> Contact Us </li>
                </ul>
            </div>
        }
    >
        <p>More details about Submit Run / Schedule Run.</p>
    </HelpPanel>
];
