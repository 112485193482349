import React, { useState, useEffect } from 'react';
import DataProvider from '../table/data-provider';
import NavigationPanel from './NavigationPanel';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES
} from '../table/results/table-config';


import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    AppLayout,
    Box,
    BreadcrumbGroup, Button,
    CollectionPreferences, FormField, Header,
    HelpPanel, Icon, Pagination,
    RadioGroup, SpaceBetween,
    Table,
    TextFilter
} from "@amzn/awsui-components-react";

// Component TableView is a skeleton of a Table using AWS-UI React components.
export default () => {
  return (
    <AppLayout
      navigation={<NavigationPanel/>} // Navigation panel content imported from './NavigationPanel.tsx'
      breadcrumbs={<Breadcrumbs/>}
      content={<ResultsTable/>}
      contentType="table"
      tools={Tools}
    />
  );
};

const ResultsTable = () => {
  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [distributions, setDistributions] = useState([]);
  const [selectedDistributions, setSelectedDistributions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    distributions,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        )
      }
    }
  );

  // fetch distributions after render of the component
  useEffect(() => {
    new DataProvider().getData('distributions', distributions => {
      setDistributions(distributions);
      setLoading(false);
    });
  }, []);

  // Keeps track of how many distributions are selected
  function headerCounter(selectedDistributions, distributions) {
    return selectedDistributions.length
      ? `(${selectedDistributions.length} of ${distributions.length})`
      : `(${distributions.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  return (
    <Table
      {...collectionProps}
      variant="full-page"
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      header={
        <TableHeader
          selectedDistributions={selectedDistributions}
          counter={headerCounter(selectedDistributions, distributions)}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail.custom)}
          pageSizePreference={{
            title: 'Page size',
            options: PAGE_SELECTOR_OPTIONS
          }}
          visibleContentPreference={{
            title: 'Select visible columns',
            options: CONTENT_SELECTOR_OPTIONS
          }}
          wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
          }}
          customPreference={(value, setValue) => (
            <FormField stretch={true} label="View as">
              <RadioGroup
                value={value}
                onChange={({ detail }) => setValue(detail.value)}
                items={CUSTOM_PREFERENCE_OPTIONS}
              />
            </FormField>
          )}
        />
      }
      wrapLines={preferences.wraplines}
      selectedItems={selectedDistributions}
      onSelectionChange={({ detail }) => setSelectedDistributions(detail.selectedItems)}
      selectionType="multi"
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={filterCounter(filteredItemsCount)}
          filteringPlaceholder="Search Results"
        />
      }
    />
  );
};

// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ selectedDistributions, counter }) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  return (
    <Header
      variant="awsui-h1-sticky"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button disabled={!isOnlyOneSelected}> View details </Button>
        </SpaceBetween>
      }
    >
      Result/s
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: ' A P P S ',
        href: '#/'
      },
      {
        text: 'Result/s',
        href: '#/results'
      }
    ]}
  />
);

// Help (right) panel content
const Tools = [
  <HelpPanel
    header={<h2>Result/s</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li> Team Wiki Home </li>
          <li> Learn More wiki links </li>
          <li> Report Issues </li>
          <li> Suggest Upgrades </li>
          <li> Contact Us </li>
        </ul>
      </div>
    }
  >
    <p>More details about Result/s</p>
  </HelpPanel>
];
