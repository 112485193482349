import React from 'react';
import { withRouter } from 'react-router-dom';
import {SideNavigation, SideNavigationProps} from "@amzn/awsui-components-react";

// Component NavigationPanel is the Side NavigationPanel component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <NavigationPanel />
function NavigationPanel(props) {
  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href.substring(1));
    }
  }

  return (
    <SideNavigation
      header={{ text: ' A P P S ', href: '#' }}
      activeHref={`#${props.location.pathname}`}
      items={items}
      onFollow={onFollowHandler}
    />
  );
}

const items: any = [
  {
    type: 'section',
    text: 'Perf Run/s',
    items: [
      { type: 'link', text: 'Schedule Run', href: '#/schedule-run' },
      { type: 'link', text: 'Scheduled Run/s', href: '#/scheduled-runs' },
    ]
  },
  {
    type: 'section',
    text: 'Perf Result/s',
    items: [{ type: 'link', text: 'Result/s', href: '#/results' }]
  }
];

export default withRouter(NavigationPanel);
