import React, { useState } from 'react';
import NavigationPanel from './NavigationPanel';
import { COLUMN_DEFINITIONS } from '../table/schedules/table-config';
import {
    AppLayout,
    Header,
    SpaceBetween,
    Box,
    BreadcrumbGroup,
    Button,
    Table,
    HelpPanel, Icon
} from "@amzn/awsui-components-react";

import { useCollection } from '@amzn/awsui-collection-hooks';

export default () => {
  return (
    <AppLayout
      navigation={<NavigationPanel/>} // Navigation panel content imported from './NavigationPanel.tsx'
      breadcrumbs={<Breadcrumbs/>}
      content={<SchedulesTable/>}
      contentType="table"
      tools={Tools}
    />
  );
};

const SchedulesTable = () => {
  const schedules = [];

  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [selectedSchedules, setSelectedSchedules] = useState([]);

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, collectionProps } = useCollection(schedules, {
    pagination: {},
    filtering: {
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No schedules</b>
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            You don't have any schedules.
          </Box>
          <Button href="#/schedule-run">Create schedule</Button>
        </Box>
      )
    }
  });

  // Keeps track of how many schedules are selected
  function headerCounter(selectedSchedules, schedules) {
    if (schedules.length === 0) return null;

    return selectedSchedules.length ? `(${selectedSchedules.length} of ${schedules.length})` : `(${schedules.length})`;
  }

  return (
    <Table
      {...collectionProps}
      variant="full-page"
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      header={<TableHeader selectedSchedules={selectedSchedules} counter={headerCounter(selectedSchedules, schedules)} />}
      selectedItems={selectedSchedules}
      onSelectionChange={({ detail }) => setSelectedSchedules(detail.selectedItems)}
      selectionType="multi"
    />
  );
};

// Table header content, shows how many schedules are selected and contains the action stripe
const TableHeader = ({ selectedSchedules, counter }) => {
  const isOnlyOneSelected = selectedSchedules.length === 1;

  return (
    <Header
      variant="awsui-h1-sticky"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button disabled={!isOnlyOneSelected}>View details</Button>
          <Button disabled={!isOnlyOneSelected}>Edit</Button>
          <Button disabled={selectedSchedules.length === 0}>Delete</Button>
          <Button href="#/schedule-run" variant="primary">
            Create Schedule
          </Button>
        </SpaceBetween>
      }
    >
      Scheduled Run/s
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: ' A P P S ',
        href: '#/'
      },
      {
        text: 'Scheduled Run/s',
        href: '#/scheduled-runs'
      }
    ]}
  />
);

// Help (right) panel content
const Tools = [
    <HelpPanel
        header={<h2>Scheduled Runs</h2>}
        footer={
            <div>
                <h3>
                    Learn more <Icon name="external" />
                </h3>
                <ul>
                    <li> Team Wiki Home </li>
                    <li> Learn More wiki links </li>
                    <li> Report Issues </li>
                    <li> Suggest Upgrades </li>
                    <li> Contact Us </li>
                </ul>
            </div>
        }
    >
        <p>More details about Scheduled Runs</p>
    </HelpPanel>
];
