import React from 'react';
import { Link } from '@amzn/awsui-components-react';

export const COLUMN_DEFINITIONS = [
  {
    id: 'runId',
    header: 'Run ID',
    cell: item => <Link>{item.id}</Link>,
    minWidth: '180px',
    sortingField: 'runId'
  },{
    id: 'scheduleId',
    cell: item => item.scheduleId,
    header: 'Schedule Id',
    minWidth: '160px',
    sortingField: 'scheduleId'
  },
  {
    id: 'scheduleName',
    cell: item => item.scheduleName,
    header: 'Schedule Name',
    minWidth: '160px',
    sortingField: 'scheduleName'
  },
  {
    id: 'runStartTime',
    header: 'Run Start Time',
    cell: item => item.runStartTime,
    minWidth: '100px',
    maxWidth: '200px',
    sortingField: 'runStartTime'
  },
  {
    id: 'runEndTime',
    header: 'Run End Time',
    cell: item => item.runEndTime,
    minWidth: '100px',
    sortingField: 'runEndTime'
  },
  {
    id: 'runStatus',
    header: 'Run Status',
    cell: item => item.runStatus,
    minWidth: '100px',
    sortingField: 'runStatus'
  },
  {
    id: 'runResults',
    header: 'Run Results',
    cell: item => <Link>Sauron Dashbord</Link>,
    minWidth: '100px',
    sortingField: 'runResults'
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Result properties',
    options: [
      { id: 'runId', label: 'Run ID', editable: true },
      {
        id: 'runStartTime',
        label: 'Run Start Time',
        editable: true
      },
      { id: 'runEndTime', label: 'Run End Time', editable: true },
      { id: 'runStatus', label: 'Run Status', editable: true },
      { id: 'runResults', label: 'Run Results', editable: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Results' },
  { value: 30, label: '30 Results' },
  { value: 50, label: '50 Results' }
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['runId', 'scheduleId', 'scheduleName', 'runStartTime', 'runEndTime', 'runStatus', 'runResults'],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
