import React from 'react';
import { Link } from '@amzn/awsui-components-react';

export const COLUMN_DEFINITIONS = [
  {
    id: 'scheduleId',
    cell: item => <Link>{item.id}</Link>,
    header: 'Schedule Id',
    minWidth: '200px'
  },{
    id: 'scheduleName',
    cell: item => item.scheduleName,
    header: 'Schedule Name',
    minWidth: '200px'
  },
  {
    id: 'releaseApkBranch',
    header: 'Release APK Branch',
    cell: item => item.releaseApkBranch,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'releaseApkVersion',
    header: 'Release APK Version',
    cell: item => item.releaseApkVersion,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'currentApkBranch',
    header: 'Current APK Branch',
    cell: item => item.currentApkBranch,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'currentApkVersion',
    header: 'Current APK Version',
    cell: item => item.currentApkVersion,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'streamCount',
    header: 'Stream Count',
    cell: item => item.streamCount,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'initModes',
    header: 'Init Modes',
    cell: item => item.initModes,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleStartDate',
    header: 'Schedule Start Date',
    cell: item => item.scheduleStartDate,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleEndDate',
    header: 'Schedule End Date',
    cell: item => item.scheduleEndDate,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleCreatedBy',
    header: 'Schedule Created By',
    cell: item => item.scheduleCreatedBy,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleCreationDate',
    header: 'Schedule Creation Date',
    cell: item => item.scheduleCreationDate,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleModifiedBy',
    header: 'Schedule Modified By',
    cell: item => item.scheduleModifiedBy,
    minWidth: '200px',
    maxWidth: '250px'
  },
  {
    id: 'scheduleModifiedDate',
    header: 'Schedule Modified Date',
    cell: item => item.scheduleModifiedDate,
    minWidth: '200px',
    maxWidth: '250px'
  }
];
