import { Route } from 'react-router-dom';
import React from 'react';
import APPSIntro from './APPSIntro';
import ScheduleRun from './ScheduleRun';
import ScheduledRuns from './ScheduledRuns';
import Results from './Results';

import '@amzn/awsui-global-styles/polaris.css';

export default function App() {
  return (
    <div>
        <Route exact path="/" component={APPSIntro} />
        <Route path="/schedule-run" component={ScheduleRun} />
        <Route path="/scheduled-runs" component={ScheduledRuns} />
        <Route path="/results" component={Results} />
    </div>
  );
}
