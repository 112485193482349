// export interface IAppSettings {
//   apiUrl: string
// }

let cache = {};

export function getAppSetting(key) {
  return cache[key];
}

export function initializeAppSettings(settings) {
  cache = { ...settings };
  Object.freeze(cache);
}
